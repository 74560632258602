export const teamPT = {
	custom: "Personalizado",
	showTableByBar: "Exibir tabela por bar",
	noEmployees: "Nenhum colaborador encontrado",
	notifications: {
		removeEmployee: "Colaborador removido com sucesso",
		removeMultipleEmployees: "Colaboradores removidos com sucesso",
		addEmployee: "Colaborador adicionado com sucesso",
		addZigTagToWaiter: "ZigTag adicionado com sucesso",
		addMultipleEmployees: {
			error: "Colaboradores não adicionados:",
			success: "Colaboradores adicionados com sucesso:",
		},
		editEmployee: "Colaborador editado com sucesso",
		editMultipleEmployees: "Colaboradores editados com sucesso",
		changePlace_one: "Colaborador transferido com sucesso!",
		changePlace_other: "Colaboradores transferidos com sucesso!",
		changePlaceError: "não foram transferidos",
	},
	header: {
		titleOrg: "Equipe",
		titlePlace: "Operadores de PDV",
		descriptionOrg:
			"Adicione, edite e delete colaboradores da Organização (usuários do dashboard).",
		descriptionPlace: "Adicione, edite e delete operadores de PDV em {{ placeName }}",
		alert: {
			link: "acesse a seção de equipe da organização.",
			text: "Para adicionar colaboradores de dashboard ou gerenciar cargos,",
		},
		searchPlaceholder: "Pesquisar",
		searchEmployee: "Buscar colaborador",
		filterByPlace: "Filtrar por local",
		closeSearch: "Fechar",
		cleanSearch: "Limpar",
		closeFilter: "Fechar filtro",
		cleanFilter: "Limpar filtro",
	},
	headerActions: {
		addEmployee: "Adicionar colaborador",
		importEmployees: "Importar colaboradores",
		selectAllEmployee: "Selecionar todos os colaboradores ({{ count }})",
		editSelectedCollaborator_one: "Editar colaborador selecionado ({{count}})",
		editSelectedCollaborator_other: "Editar colaboradores selecionados ({{count}}) ",
		deleteSelectedCollaborator_one: "Excluir colaborador selecionado ({{count}})",
		deleteSelectedCollaborator_other: "Excluir colaboradores selecionados ({{count}}) ",
		uncheckEmployee_one: "Desmarcar colaborador selecionado ({{count}})",
		uncheckEmployee_other: "Desmarcar colaboradores selecionados ({{count}}) ",
		changePlaceSelectedCollaborator_one:
			"Alterar place do colaborador selecionado ({{count}})",
		changePlaceSelectedCollaborator_other:
			"Alterar place dos colaboradores selecionados ({{count}})",
		buttonBulkActions: {
			titleBtn: "Ações em massa",
			titleModal: "Ações em massa ({{ count }} selecionados)",
			titleModalMode: "Modo de seleção",
			removeSelected: "Remover seleção dos operadores ({{count}})",
			selectAllByRole: "Selecionar todos os operadores de cargo",
			selectRole: "Selecionar todos {{name}}",
			titleRole: "Personalizado",
			editSelectedCollaborator_one: "Editar colaborador selecionado",
			editSelectedCollaborator_other: "Editar colaboradores selecionados",
			deleteSelectedCollaborator_one: "Excluir colaborador selecionado",
			deleteSelectedCollaborator_other: "Excluir colaboradores selecionados",
			changePlaceSelectedCollaborator_one: "Alterar place do colaborador selecionado",
			changePlaceSelectedCollaborator_other:
				"Alterar place dos colaboradores selecionados",
		},
	},
	columns: {
		name: "Colaborador",
		username: "Login",
		role: "Cargo",
		pub: {
			none: "Nenhum bar",
			label: "Bares",
			mainBar: "Bar principal",
		},
		places: "Locais",
		placesNone: "Nenhum local selecionado",
		deleteEmployee: "Excluir colaborador",
		updateEmployee: "Editar colaborador",
		duplicateEmployee: "Duplicar colaborador",
		changePlace: "Alterar local",
	},
	form: {
		create: {
			title: "Novo Operador",
			descriptionOrg: "Crie um colaborador da organização (usuário do dashboard)",
			descriptionPlace: "Crie um operador em {{ placeName }}",
		},
		update: {
			title: "Editar Operador",
			descriptionOrg: "Edite um colaborador da organização (usuário do dashboard)",
			descriptionPlace: "Edite um operador em {{ placeName }}",
		},
		multipleUpdate: {
			title: "Editar Operadores {{count}}",
			descriptionOrg:
				"Edite os colaboradores selecionados da organização (usuário do dashboard)",
			descriptionPlace: "Edite {{count}} operadores em {{ placeName }}",
			warning: {
				title: "Campos não preenchidos",
				description:
					"Os campos não preenchidos mantém os valores originais do colaborador.",
			},
		},
		actions: {
			discardCreate: "Descartar criação",
			discardEdit: "Descartar alterações",
			create: "Criar colaborador",
			save: "Salvar alterações",
		},
		dataEmployees: {
			title: "Dados do Colaborador",
			name: {
				label: "Nome",
				placeholder: "Insira o nome",
				required: "O nome é obrigatório",
				min: "O nome deve ter no mínimo 3 caracteres",
			},
			username: {
				label: "Login",
				placeholder: "Insira o login",
				required: "O login é obrigatório",
				lowercase: "O login deve ser todo em minúsculo",
				noSpaces: "O login não pode ter espaços",
			},
			password: {
				label: "Senha",
				placeholder: "Crie a senha de acesso",
				required: "A senha é obrigatória",
				min: "A senha deve ter no mínimo 6 caracteres",
				notEmpty: "A senha não pode ser vazia",
			},
		},
		renameDataEmployees: {
			title: "Nome",
			name: {
				label: "Renomear",
				placeholder: "Renomeie colaboradores em massa",
			},
			initialNameNumber: {
				label: "Numerar a partir de",
				placeholder: "Insira o valor inicial",
				nonnegative: "O valor deve ser maior que 0",
				required: "Os campos são obrigatórios",
			},
			renameInitialNameNumber: {
				validation: "Os campos devem ser preenchidos juntos",
			},
			info:
				"Ao renomear em massa, todos os colaboradores selecionados passam a ter o mesmo nome. Para diferenciar, numeramos automaticamente à partir do número que você inserir na caixa de texto acima",
		},
		placeSection: {
			title: "Locais",
			selectedPlaces: {
				required: "Ao menos um local deve ser selecionado",
				selectAll: "Selecionar todos",
				none: "Nenhum local selecionado",
				min: "Ao menos um local deve ser selecionado",
			},
		},
		extraInfoSection: {
			title: "Informações Extras",
			pub: {
				label: "Bares",
				labelMainBar: "Bar principal / ponto de venda",
				placeholder: "Selecione um bar de {{ placeName }}",
			},
			fichasAmount: {
				label: "Quantidade de vias em ficha",
				placeholder: "Insira a quantidade",
				nonnegative: "A quantidade deve ser maior ou igual a 0",
			},
			menuVisualizationType: {
				label: "Visualização do cardápio",
				placeholder: "Insira a quantidade",
				tooltip: {
					Grid: "Foto grande: 2 colunas",
					SmallGrid: "Foto pequena: 3 colunas",
					NoPicture: "Sem foto: 3 colunas",
				},
				type: {
					Grid: "Foto grande",
					SmallGrid: "Foto pequena",
					NoPicture: "Sem foto",
				},
			},
		},
		rolesAndPermissions: {
			titleOrg: "Cargos e Permissões de Dashboard",
			titlePlace: "Cargos e Permissões de PDV",
			employeeRole: {
				label: "Cargo",
				placeholder: "Selecione um cargo",
				none: "Nenhum cargo",
				custom: "Personalizado",
				required: "O cargo é obrigatório",
			},
			zigTag: {
				label: "ZigTag",
				placeholder: "Digite o número do ZigTag",
				nonnegative: "O valor deve ser maior ou igual a 0",
			},
			permissions: {
				operationalPermissions: "Permissões de PDV",
				managerialPermissions: "Permissões gerenciais do PDV",
				restrictivePermissions: "Bloqueios do PDV",
				permissionsOrg: "Acesso ao dashboard",
			},
			type: {
				label: "Tipo de colaborador",
				placeholder: "Nenhum",
			},
		},
		moreThanOneEmployee: {
			title: "Adicionar mais de um colaborador",
			initialValue: "Insira valor inicial",
			finalValue: "Insira valor final",
			validationUntil: "O valor final deve ser maior que inicial",
			negativeValue: "Os valores devem ser maiores que 0",
			required: "Os campos são obrigatórios",
			nonnegative: "O valor deve ser maior ou igual a 0",
			since: {
				label: "De",
			},
			until: {
				label: "Até",
			},
		},
		changePlace: {
			title: "Trocar place dos colaboradores",
			description:
				"Selecione um novo local para os colaboradores selecionados: {{ employeeNames }}",
			place: {
				label: "Local",
				placeholder: "Selecione um local",
				required: "O local é obrigatório",
			},
			mainBar: {
				label: "Bar principal",
				placeholder: "Selecione um bar",
			},
			barIds: {
				label: "Bares",
				placeholder: "Selecione um ou mais bares",
			},
		},
	},
	deleteEmployeeModal: {
		title: "Deseja excluir colaborador?",
		description:
			"Ao excluir colaborador, o seu login será invalidado. Essa ação não pode ser desfeita.",
		buttonCancel: "Fechar",
		buttonConfirm_one: "Excluir colaborador ({{ count }})",
		buttonConfirm_other: "Excluir colaboradores ({{ count }})",
	},
	reportPrint: {
		title: "Importar colaboradores",
		btnDownloadTemplate: "Baixar modelo",
		spreadsheet: "Importar planilha",
		sheetFileName: "Colaboradores",
		sheetColumns: {
			name: { label: "Nome do Colaborador", sample: "William" },
			username: { label: "Login", sample: "willzig" },
			password: { label: "Senha", sample: "123456" },
			zigTag: { label: "ZigTag (opcional)", sample: "123Z7JH" },
			fichasAmount: { label: "Quantidade de vias em ficha", sample: "2" },
			multiple: { label: "Quantidade para adicionar em lote", sample: "1" },
		},
		role: {
			label: "Cargo",
			placeholder: "Selecione um cargo",
			none: "Nenhum cargo",
			custom: "Personalizado",
		},
		pub: {
			label: "Bares",
			labelMain: "Bar principal",
			placeholder: "Selecione um bar",
			none: "Nenhum bar",
		},
		permissions: {
			label: "Permissões",
		},
		info:
			"Todos os funcionários serão adicionados em {{ placeName }} com o cargo e bar selecionado acima",
		discard: "Descartar importação",
		finalize: "Finalizar importação",
	},
	validateTeamReport: {
		emptySheet: "Nenhum dado encontrado na planilha",
		existingEmployees: "Colaboradores já existentes: {{ usernames }}",
		duplicateUsernames: "Logins duplicados encontrados: {{ usernames }}",
		invalidSheet: "Dados inválidos encontrados: {{ errors }}",
		invalidRow: "Linha {{ index }}: {{ errors }}",
		invalid: {
			name: "Nome inválido",
			username: "Login inválido",
			usernameChars: "Login contém caracteres inválidos",
			password: "Senha inválida",
			multiple: "Valor de múltiplo inválido",
			multipleGreaterThan: "Múltiplo maior que o permitido",
		},
	},
};
