import { RootStore } from "./";
import enterprise from "#resources/api/enterprise-client";
import { fetchModel } from "#resources/helpers/fetch-model";
import {
	showErrorNotification,
	showSuccessNotification,
} from "#resources/helpers/notifications";
import {
	Cashier,
	CashierAdjustment,
	CashierClosingObs,
	CloseMethod,
	CloseMethodEnum,
	CloseValue,
} from "#resources/api/enterprise-generated";
import i18n from "#i18n/index";
import { CashierAdjustmentRequestParams } from "#pages/event/event-reports/cashier-closings/cashier-adjustment/types";

const t = i18n.t;

export class CashierStore {
	public rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	public clearAll = () => {
		this.cashierDetailsAtEvent.reset();
	};

	public getPossibleCloseMethods = new fetchModel<{}, CloseMethod[], CloseMethod[]>({
		fnPromise: args => enterprise.getPossibleCloseMethods(args),
		transform: closemethods =>
			closemethods.sort((a, b) => {
				const alabel = a.method + (a.brand || "");
				const blabel = b.method + (b.brand || "");
				return alabel.localeCompare(blabel);
			}),
		onError: err => showErrorNotification(err.message),
	});

	public cashierDetailsAtEvent = new fetchModel<
		{ eventId: string; barIds?: string[] | null | undefined },
		Cashier[]
	>({
		fnPromise: args => enterprise.cashierDetailsAtEvent(args),
		onError: err => showErrorNotification(err.message),
	});

	public cashierDetailsAtPlace = new fetchModel<
		{
			since: Date;
			until: Date;
			placeId: string;
			barIds?: string[] | null | undefined;
		},
		Cashier[]
	>({
		fnPromise: args => enterprise.cashierDetailsAtPlace(args),
		onError: err => showErrorNotification(err.message),
	});

	public bleedCashier = new fetchModel<CashierAdjustmentRequestParams, void>({
		fnPromise: args => enterprise.bleedCashier(args),
		onSuccess: (_value, args) => {
			return showSuccessNotification(
				args.value > 0
					? t("store:cashierStore.bleedCashier_other")
					: t("store:cashierStore.bleedCashier_zero"),
			);
		},
		onError: err => showErrorNotification(err.message),
	});

	public updateCashierClosing = new fetchModel<
		{
			eventId: string;
			cashierId: string;
			values: CloseValue[];
		},
		Cashier
	>({
		fnPromise: args => enterprise.updateCashierClosingValues(args),
		onError: err => showErrorNotification(err.message),
	});

	public closeCashier = new fetchModel<
		{
			eventId: string;
			cashierId: string;
		},
		void
	>({
		fnPromise: args => enterprise.closeCashier(args),
		onError: err => showErrorNotification(err.message),
	});

	public reopenCashier = new fetchModel<
		{
			eventId: string;
			cashierId: string;
		},
		void
	>({
		fnPromise: args => enterprise.reopenCashier(args),
		onError: err => showErrorNotification(err.message),
	});

	public getCashierClosingObs = new fetchModel<
		{
			eventId: string;
			cashierId: string;
		},
		CashierClosingObs[]
	>({
		fnPromise: args => enterprise.getCashierClosingObs(args),
		onError: err => showErrorNotification(err.message),
	});

	public addCashierClosingObs = new fetchModel<
		{
			eventId: string;
			cashierId: string;
			obs: string;
		},
		void
	>({
		fnPromise: args => enterprise.addCashierClosingObs(args),
		onSuccess: () =>
			showSuccessNotification(t("store:cashierStore.addCashierClosingObs")),
		onError: err => showErrorNotification(err.message),
	});

	public createCashierChangeLEGACY = async (cashierId: string, value: number) => {
		try {
			const eventId = this.rootStore.eventStore.event?.id!;
			await this.bleedCashier.fetch({ eventId, cashierId, value: -value, obs: null });
			showSuccessNotification(t("store:cashierStore.createCashierChangeLEGACY"));
			this.cashierDetailsAtEvent.fetch({ eventId });
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	public createBleedLEGACY = async (cashierId: string, value: number) => {
		try {
			const eventId = this.rootStore.eventStore.event?.id!;
			await this.bleedCashier.fetch({ eventId, cashierId, value, obs: null });

			showSuccessNotification(t("store:cashierStore.createBleedLEGACY"));
			this.cashierDetailsAtEvent.fetch({ eventId });
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	public createMultipleChangesLEGACY = async (cashiersId: string[], value: number) => {
		const errors: string[] = [];
		const eventId = this.rootStore.eventStore.event?.id!;
		for (let i = 0; i < cashiersId.length; i++) {
			const cashierId = cashiersId[i];
			try {
				await this.bleedCashier.fetch({ eventId, cashierId, value: -value, obs: null });
			} catch (error) {
				if (error instanceof Error) {
					errors.push(error.message);
				}
			}
		}

		if (errors.length === 0) {
			showSuccessNotification(t("store:cashierStore.createMultipleChangesLEGACY"));
		} else {
			showErrorNotification(
				t("store:cashierStore.createMultipleChangesLEGACYError", {
					errors: errors.length,
					cashiersId: cashiersId.length,
					jsonErrors: JSON.stringify(errors),
				}),
			);
		}
		this.cashierDetailsAtEvent.fetch({ eventId });
	};

	public updateCashierClosingLEGACY = (
		cashierId: string,
		values: OldCashierClosingValues,
	) => {
		const eventId = this.rootStore.eventStore.event?.id!;

		this.updateCashierClosing
			.fetch({
				eventId,
				cashierId,
				values: TransformOldCashierClosingsValuesToNew(values),
			})
			.then(async () => {
				showSuccessNotification(t("store:cashierStore.updateCashierClosingLEGACY"));
				await this.cashierDetailsAtEvent.fetch({ eventId });
			})
			.catch(err => showErrorNotification(err.message));
	};

	public getPlaceCashierAdjustments = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
		},
		CashierAdjustment[]
	>({
		fnPromise: args => enterprise.getPlaceCashierAdjustments(args),
		onError: error => showErrorNotification(error.message),
	});

	public getCashierAdjustments = new fetchModel<
		{
			eventId: string;
		},
		CashierAdjustment[]
	>({
		fnPromise: args => enterprise.getCashierAdjustments(args),
		onError: error => showErrorNotification(error.message),
	});
}

interface OldCashierClosingValues {
	cashValue: number;
	creditValue: number;
	debitValue: number;
	voucherValue: number;
}

function TransformOldCashierClosingsValuesToNew(
	value: OldCashierClosingValues,
): CloseValue[] {
	const methodEquivalence: Record<keyof OldCashierClosingValues, CloseMethodEnum> = {
		cashValue: "cash",
		creditValue: "credit",
		debitValue: "debit",
		voucherValue: "voucher",
	};

	return Object.entries(value).map(([method, value]) => ({
		method: methodEquivalence[method as keyof OldCashierClosingValues],
		value,
		brand: null,
	}));
}
