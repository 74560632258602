import { createMutationFactory } from "../mutation-factory";

export const {
	useApiHook: useExportAllTransactionProductsXlsxPlace,
} = createMutationFactory("exportAllTransactionProductsXlsxPlace");

export const { useApiHook: useExportAllTransactionProductsXlsx } = createMutationFactory(
	"exportAllTransactionProductsXlsx",
);

export const { useApiHook: useExportAllPlaceOrEventActions } = createMutationFactory(
	"exportAllPlaceOrEventActions",
);
