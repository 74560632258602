import constate from "constate";
import { useState } from "react";
import { GroupBy, SalesReportProps } from "../types";
import { useDebounceValue } from "usehooks-ts";
import { GetReportByScope, PaymentMethod } from "#resources/api/enterprise-generated";
import { usePlaceProducts } from "#resources/hooks/integrations/products/query";
import { useGetBars } from "#resources/hooks/integrations/bars/query";
import { useGetEmployeesV2 } from "#resources/hooks/integrations/team";
import {
	useExportAllTransactionProductsXlsx,
	useExportAllTransactionProductsXlsxPlace,
} from "#resources/hooks/integrations/transactions/mutation";
import { DEBOUNCE_TIMEOUT } from "./constants";
import { PaymentMethodLabels } from "#resources/helpers/humanize";
import { useGTMEvent } from "#resources/hooks/metrics";
import { useTranslation } from "react-i18next";
import { useGetPlainCategories } from "#resources/hooks/integrations/categories/query";
import useFilters from "#pages/place/bar-reports/sales-report/hooks/use-filters";
import {
	useGetAllPlaceSalesInEventXls,
	useGetAllPlaceSalesXls,
	useProductsSoldByBarXlsx,
	useProductsSoldXlsx,
} from "#resources/hooks/integrations/sales/mutation";

type SalesReportTab = "all-sales" | "total-sales";

const useSalesReportHook = (props: SalesReportProps) => {
	const { sendSelectContent } = useGTMEvent();
	const { t } = useTranslation("place", {
		keyPrefix: "barReports.salesReport",
	});
	const { since: _since, until: _until, placeId, eventLevel, eventId } = props;

	const [selectedTab, setSelectedTab] = useState<SalesReportTab>("all-sales");

	const {
		rangeDate,
		setRangeDate,
		clientNameFilter,
		setClientNameFilter,
		employeesFilter,
		setEmployeesFilter,
		paymentMethodsFilter,
		setPaymentMethodsFilter,
		transactionsFilter,
		setTransactionsFilter,
		productsFilter,
		setProductsFilter,
		barsFilter,
		setBarsFilter,
		categoriesFilter,
		setCategoriesFilter,
		since,
		until,
	} = useFilters(props);

	const [_groupBy, setGroupBy] = useState<GroupBy>("bar");
	const [groupBy] = useDebounceValue(_groupBy, DEBOUNCE_TIMEOUT);

	const {
		mutateAsync: doExportAllTransactionProductsXlsxPlace,
		isPending: exportAllTransactionProductsXlsxPlaceIsPending,
	} = useExportAllTransactionProductsXlsxPlace({
		onSuccess(url) {
			window.open(url, "_blank");
		},
	});

	const {
		mutateAsync: doExportAllTransactionProductsXlsx,
		isPending: exportAllTransactionProductsXlsxIsPending,
	} = useExportAllTransactionProductsXlsx({
		onSuccess(url) {
			window.open(url, "_blank");
		},
	});

	const {
		mutateAsync: doGetAllPlaceSalesXls,
		isPending: getAllPlaceSalesXlsIsPending,
	} = useGetAllPlaceSalesXls({
		onSuccess(url) {
			window.open(url, "_blank");
		},
	});

	const {
		mutateAsync: doProductsSoldXlsx,
		isPending: productsSoldXlsxIsPending,
	} = useProductsSoldXlsx({
		onSuccess(url) {
			window.open(url, "_blank");
		},
	});

	const {
		mutateAsync: doProductsSoldByBarXlsx,
		isPending: productsSoldByBarXlsxIsPending,
	} = useProductsSoldByBarXlsx({
		onSuccess(url) {
			window.open(url, "_blank");
		},
	});

	const {
		mutateAsync: doGetAllPlaceSalesInEventXls,
		isPending: getAllPlaceSalesInEventXlsIsPending,
	} = useGetAllPlaceSalesInEventXls({
		onSuccess(url) {
			window.open(url, "_blank");
		},
	});

	const exportationIsLoading =
		exportAllTransactionProductsXlsxPlaceIsPending ||
		getAllPlaceSalesXlsIsPending ||
		productsSoldXlsxIsPending ||
		productsSoldByBarXlsxIsPending ||
		exportAllTransactionProductsXlsxIsPending ||
		getAllPlaceSalesInEventXlsIsPending;

	const { data: placeProducts = [] } = usePlaceProducts(
		{ placeId },
		{ enabled: selectedTab === "total-sales" },
	);
	const { data: bars = [] } = useGetBars(
		{ placeId },
		{ enabled: selectedTab === "total-sales" && groupBy === "bar" },
	);
	const { data: employees = [] } = useGetEmployeesV2({});
	const { data: categories = [] } = useGetPlainCategories(
		{},
		{
			enabled: groupBy === "no-group",
		},
	);

	const productsOptions = placeProducts.map(({ id, name }) => ({
		value: id,
		label: name,
	}));

	const barOptions = bars.map(({ id, name }) => ({
		value: id,
		label: name,
	}));

	const employeesOptions = employees.map(({ id, name }) => ({
		value: id,
		label: name,
	}));

	const categoriesOptions = categories.map(({ id, name }) => ({
		value: id,
		label: name,
	}));

	const paymentMethodsOptions = Object.entries(
		PaymentMethodLabels,
	).map(([value, label]: [PaymentMethod, string]) => ({ value, label }));

	const exportSoldProductsConsolidated = async () => {
		const scope: GetReportByScope = {
			event: eventId ? { id: eventId } : null,
			place: eventId
				? null
				: {
						id: placeId,
						period: {
							since,
							until,
						},
				  },
		};

		const barIds = barsFilter.length ? barsFilter : null;
		const productIds = productsFilter.length ? productsFilter : null;
		const sources = transactionsFilter.length ? transactionsFilter : null;

		switch (groupBy) {
			case "bar":
				await doProductsSoldByBarXlsx({
					filters: {
						barIds,
						productIds,
						sources,
					},
					scope,
				});
				break;
			case "no-group":
				await doProductsSoldXlsx({
					filters: {
						barIds,
						productIds,
						sources,
					},
					scope,
				});
				break;
			default:
				break;
		}
	};

	const exportDetailedSold = async () => {
		const customerName = clientNameFilter ?? null;
		const employeeIds = employeesFilter.length ? employeesFilter : null;
		const paymentMethods = paymentMethodsFilter.length ? paymentMethodsFilter : null;

		const filters = {
			customerName,
			employeeIds,
			paymentMethods,
		};

		if (eventId) {
			return await doGetAllPlaceSalesInEventXls({
				eventId,
				filters,
				placeId,
			});
		}

		await doGetAllPlaceSalesXls({
			filters,
			period: { since, until },
			placeId,
		});
	};

	const exportSoldProductsAnalytics = async () => {
		if (eventId) {
			await doExportAllTransactionProductsXlsx({ eventId });
			return;
		}

		await doExportAllTransactionProductsXlsxPlace({
			placeId,
			since,
			until,
		});
	};

	const exportXls = async (
		context: "detailed-sold" | "sold-products-analytic" | "sold-products-consolidated",
	) => {
		switch (context) {
			case "detailed-sold":
				await exportDetailedSold();
				break;
			case "sold-products-consolidated":
				await exportSoldProductsConsolidated();
				break;
			case "sold-products-analytic":
				await exportSoldProductsAnalytics();
				break;
			default:
				break;
		}
	};

	const handleSelectTab = (tab: SalesReportTab) => {
		const eventByTab: Record<SalesReportTab, { label: string; event_track: string }> = {
			"all-sales": {
				event_track: "clk_allproductsales_report",
				label: t("allSalesTab"),
			},
			"total-sales": {
				event_track: "clk_productsoldby_report",
				label: t("totalSalesTab"),
			},
		};

		sendSelectContent(eventByTab[tab]);
		setSelectedTab(tab);
	};

	return {
		/** General */
		eventLevel: Boolean(eventLevel),
		eventId,
		selectedTab,
		setSelectedTab: handleSelectTab,
		placeId,
		since,
		until,

		/** Filters */
		rangeDate,
		setRangeDate,
		clientNameFilter,
		setClientNameFilter,
		employeesFilter,
		setEmployeesFilter,
		paymentMethodsFilter,
		setPaymentMethodsFilter,
		transactionsFilter,
		setTransactionsFilter,
		productsFilter,
		setProductsFilter,
		barsFilter,
		setBarsFilter,
		groupBy,
		setGroupBy,
		categoriesFilter,
		setCategoriesFilter,

		/** Actions */
		exportationIsLoading,
		exportXls,

		/** Options */
		productsOptions,
		barOptions,
		employeesOptions,
		paymentMethodsOptions,
		categoriesOptions,
	};
};

export const [
	SalesReportProvider,
	useSalesReport,
	useSalesOptions,
	useSalesFilters,
] = constate(
	useSalesReportHook,
	/** General */
	value => value,
	/** Options */
	({
		barOptions,
		productsOptions,
		employeesOptions,
		paymentMethodsOptions,
		categoriesOptions,
	}) => ({
		barOptions,
		productsOptions,
		employeesOptions,
		paymentMethodsOptions,
		categoriesOptions,
	}),
	/** Filters */
	value => ({
		rangeDate: value.rangeDate,
		setRangeDate: value.setRangeDate,
		clientsFilter: value.clientNameFilter,
		setClientsFilter: value.setClientNameFilter,
		employeesFilter: value.employeesFilter,
		setEmployeesFilter: value.setEmployeesFilter,
		paymentMethodsFilter: value.paymentMethodsFilter,
		setPaymentMethodsFilter: value.setPaymentMethodsFilter,
		transactionsFilter: value.transactionsFilter,
		setTransactionsFilter: value.setTransactionsFilter,
		productsFilter: value.productsFilter,
		setProductsFilter: value.setProductsFilter,
		barsFilter: value.barsFilter,
		setBarsFilter: value.setBarsFilter,
		groupBy: value.groupBy,
		setGroupBy: value.setGroupBy,
		categoriesFilter: value.categoriesFilter,
		setCategoriesFilter: value.setCategoriesFilter,
	}),
);
