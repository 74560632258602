import React, { useMemo, useRef } from "react";
import { Cell, Grid } from "#components/grid-structure";
import { DataCardSummary } from "#components/data-card-summary/data-card-summary";
import { Button, ButtonSelect, Header } from "#components/primitive";
import Icon from "#components/icon";
import { TableAllSales } from "./table-all-sales";
import { TableTotalSales } from "./table-total-sales";
import { ContextOptions } from "#components/context-options";
import { withProvider } from "#resources/hocs/with-provider";
import { SalesReportProvider, useSalesReport } from "./context";
import { SalesReportProps } from "./types";
import { useCurrency } from "#resources/hooks";
import { useTranslation } from "react-i18next";
import { ReportPrintTemplate } from "#components/report-print-template";
import { PrintButton } from "#components/print-button";
import { useGetAllPlaceSalesSummaryByLevel } from "#pages/place/bar-reports/sales-report/hooks";

import s from "./sales-report.module.scss";

const _SalesReport = (props: SalesReportProps) => {
	const printRef = useRef<any>(null);

	const { t } = useTranslation("place", { keyPrefix: "barReports.salesReport" });
	const { formatCurrency } = useCurrency();
	const {
		selectedTab,
		setSelectedTab,
		rangeDate,
		setRangeDate,
		since,
		until,
		exportXls,
		exportationIsLoading,
	} = useSalesReport();

	const { data: summaryData } = useGetAllPlaceSalesSummaryByLevel({});

	const totalizers = useMemo(() => {
		if (!summaryData) {
			const initialCurrencyValue = formatCurrency(0);
			return {
				productsSold: 0,
				subtotal: initialCurrencyValue,
				discounts: initialCurrencyValue,
				total: initialCurrencyValue,
			};
		}

		return {
			productsSold: summaryData.productsSold,
			subtotal: formatCurrency(summaryData.productSales),
			discounts: formatCurrency(summaryData.discount),
			total: formatCurrency(summaryData.totalInSales || 0),
		};
	}, [summaryData]);

	const showHeaderFilters = !props.eventLevel;

	return (
		<div className={s.container}>
			<Header
				title={t("title")}
				dateProps={
					!showHeaderFilters
						? undefined
						: {
								rangePicker: {
									format: "DD/MM/YYYY",
									defaultValue: rangeDate,
									onChange: values => {
										const since = values?.[0];
										const until = values?.[1];

										if (since && until) {
											setRangeDate([since, until]);
										}
									},
									allowClear: false,
								},
						  }
				}
				buttonActions={{
					primary: null,
					extra: (
						<div className={s.wrapperExtraButton}>
							<ContextOptions.Menu
								classNameList={s.exportOptionsList}
								customTrigger={
									<Button
										loading={exportationIsLoading}
										className={s.exportButton}
										trailingIcon={<Icon icon="arrow-down" />}
									>
										{t("exportButton")}
									</Button>
								}
							>
								<ContextOptions.Label
									className={s.exportOptionsLabel}
									label={t("exportLabelWithFilters")}
								/>

								<ContextOptions.Option
									action={() => exportXls("detailed-sold")}
									label={t("exportDetailedSold")}
								/>

								<ContextOptions.Option
									action={() => exportXls("sold-products-analytic")}
									label={t("exportSoldProductsAnalytic")}
								/>

								<ContextOptions.Option
									action={() => exportXls("sold-products-consolidated")}
									label={t("exportSoldProductsConsolidated")}
								/>
							</ContextOptions.Menu>

							<PrintButton useRef={printRef.current} />
						</div>
					),
				}}
			/>
			<ReportPrintTemplate
				className={s.printContainer}
				notHideContent
				title={t("title")}
				since={since}
				until={until}
				ref={ref => (printRef.current = ref)}
			>
				<Grid
					className={s.grid}
					areas={[["products-sold", "subtotal", "discounts", "amount-products-sold"]]}
				>
					<Cell area="products-sold">
						<DataCardSummary
							color="primary"
							bgColor="neutral"
							icon={<Icon icon="cloche" />}
							label={t("productsSoldLabel")}
							value={totalizers.productsSold}
							fullHeight
							fullWidth
						/>
					</Cell>

					<Cell area="subtotal">
						<DataCardSummary
							color="primary"
							bgColor="neutral"
							icon={<Icon icon="money-circle" />}
							label={t("subtotalLabel")}
							value={totalizers.subtotal}
							tooltip={t("subtotalTooltip")}
							fullWidth
							fullHeight
						/>
					</Cell>

					<Cell area="discounts">
						<DataCardSummary
							color="error"
							bgColor="neutral"
							icon={<Icon icon="promotions" />}
							label={t("discountsLabel")}
							value={totalizers.discounts}
							fullHeight
							fullWidth
						/>
					</Cell>

					<Cell area="amount-products-sold">
						<DataCardSummary
							color="success"
							bgColor="neutral"
							icon={<Icon icon="money-circle" />}
							label={t("totalProductsSoldLabel")}
							value={totalizers.total}
							tooltip={t("totalProductsSoldTooltip")}
							fullHeight
							fullWidth
						/>
					</Cell>
				</Grid>

				<div className={s.tableSelectors}>
					<ButtonSelect
						onClick={() => setSelectedTab("all-sales")}
						state={selectedTab === "all-sales" ? "selected" : "default"}
					>
						{t("allSalesTab")}
					</ButtonSelect>

					<ButtonSelect
						onClick={() => setSelectedTab("total-sales")}
						state={selectedTab === "total-sales" ? "selected" : "default"}
					>
						{t("totalSalesTab")}
					</ButtonSelect>
				</div>

				{selectedTab === "all-sales" && <TableAllSales />}

				{selectedTab === "total-sales" && <TableTotalSales />}
			</ReportPrintTemplate>
		</div>
	);
};

export const SalesReport = withProvider<SalesReportProps>(SalesReportProvider)(
	_SalesReport,
);
