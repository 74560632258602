export const adjustments = {
	title: "Adjustments",
	filename: "adjustments",
	noDataToShow: "There is nothing to show for the selected period",
	label: {
		pos: "Billing on Non-Integrated Machines (POS)",
		posTax: "Acquisition Fee on Non-Integrated Machines (POS)",
		operatorFee: "Operator's Daily",
		systemSetup: "System Setup",
		wifiSetup: "Wifi Setup",
		equipmentsPurchase: "Purchase of Equipment",
		equipmentsRental: "Equipment Rental",
		zigDevicePurchase: "Purchase of Commands",
		equipmentsMaintenance: "Equipment maintenance",
		wifiAssistance: "Wifi Technical Assistance",
		zigFee: "Zig Fee",
		totemFee: "Totem rent",
		cardActivations: "Activation Fee",
		genericOperationsFee: "General Operating Expenses",
		genericServices: "Other Services and Charges",
		bonifications: "Other Bonuses",
		expiredRecharges: "Expired recharges",
		notUsedRecharges: "Recharges Leftovers ",
		generalZigMachineTPV: "Billing via ZIG integrated acquiring",
		generalZigMachineTax: "Acquisition Rates on Integrated Machines",
		anticipationFee: "Anticipation Fee",
		payments: "Payments",
		paymentsTax: "Payment Fee to Suppliers",
		reimbursements: "Refunds to Customers",
		withdraw: "Withdrawals",
		balanceAdjustment: "Balance Balancing",
		renegotiation: "Renegotiation",
		advance: "Advance",
		advanceFee: "Advance Fee",
		balanceRetention: "Balance Retention",
		others: "Others",
		residueRemain: "Waste Retention",
		missingCards: "Missing Cards",
		billingInvoice: "Billing Invoice",
		guaranteedMinimumSupplement: "Guaranteed Minimum Supplement",
		commandFiscalPurchase: "Command Fiscal Purchase",
		accountsReceivableLegal: "Accounts Receivable - Legal",
		cardDiscount: "Card Discount",
		discountGeneralOperatingExpenses: "Discount General Operating Expenses",
		discountOperatorDailyRate: "Discount Operator Daily Rate",
		generalDiscount: "General Discount",
		dailyCoordinatorRate: "Daily Coordinator Rate",
		dailyHeadsRate: "Daily Heads Rate",
		setupDailyCoordinatorRate: "Setup Daily Coordinator Rate",
		setupDailyHeadsRate: "Setup Daily Heads Rate",
		setupDailySupervisorsRate: "Setup Daily Supervisors Rate",
		setupDailyTechnicalRate: "Setup Daily Technical Rate",
		setupDailyTraineeRate: "Setup Daily Trainee Rate",
		dailySupervisorRate: "Daily Supervisor Rate",
		dailyTechnicianRate: "Daily Technician Rate",
		dailyTraineeRate: "Daily Trainee Rate",
		damagedEquipment: "Damaged Equipment",
		integratedAPPBilling: "Integrated APP Billing  ",
		apartCreditBilling: "Apart Credit Billing",
		apartDebitBilling: "Apart Debit Billing",
		integratedDebitBilling: "Integrated Credit Billing",
		apartBillingPIX: "Apart Billing  PIX ",
		integratedPIXBilling: "Integrated PIX Billing",
		integratedAdvancedRechargeBilling: "Integrated Advanced Recharge Billing",
		interestandPenalties: "Interest and Penalties",
		softwareLicense: "Software License",
		replacedMachines: "Replaced Machines",
		minimumGuaranteedpermachine: "Minimum Guaranteed per machine",
		otherEquipment: "Other Equipment",
		rebate: "Rebate",
		setupAdvancedRecharge: "Setup Advanced Recharge",
		setupRefundSite: "Setup Refund Site",
		acquirementfeeforIntegratedZIGApplication:
			"Acquirement fee for Integrated ZIG Application",
		acquiringfeeonapartCredit: "Acquiring fee on apart Credit",
		acquiringfeeforIntegratedCredit: "Acquiring fee for Integrated Credit",
		acquiringfeeforapartDebit: "Acquiring fee for apart Debit",
		acquiringfeeonIntegratedDebit: "Acquiring fee on Integrated Debit",
		acquiringfeeforapartPIX: "Acquiring fee for apart PIX",
		acquiringfeeforIntegratedPIX: "Acquiring fee for Integrated PIX",
		acquiringfeeforAdvancedRecharge: "Acquiring fee for Advanced Recharge",
		refundfee: "Refund fee",
		relatedpartyretentionfee: "Related party retention fee",
		rappiFee: "Rappi Fee",
		SMSFee: "SMS Fee",
		residualFee: "Residual Fee",
		cardtoCashExchange: "Card to Cash Exchange",
		cardtoProductExchange: "Card to Product Exchange",
		travelFood: "Travel Food",
		shipping: "Shipping",
		accommodation: "Accommodation",
		transport: "Transport",
		pixRefundFee: "PIX Refund Fee",
		softwareLicenseMachine: "Software License Machine",
		softwareLicenseTotem: "Software License Totem",
		integratedAdvanceDebitBilling: "Integrated Advance Debit Billing",
		integratedAdvanceCreditBilling: "Integrated Advance Credit Billing",
		integratedAdvancePixBilling: "Integrated Advance Pix Billing",
		integratedAdvanceDebitFee: "Integrated Advance Debit Fee",
		integratedAdvanceCreditFee: "Integrated Advance Credit Fee",
		integratedAdvancePixFee: "Integrated Advance Pix Fee",
		zeDeliveryDiscount: "Zé Delivery Discount",
		ticketPrinting: "Ticket Printing",
		validatorRental: "Validator License",
		zeDeliveryRetention: "Zé Delivery Retention",
		valueAddedTax: "Value-added Tax",
		moneyTPV: "Money TPV",
		acquiringFeeForIntegratedInstallmentCredit:
			"Acquiring Fee for Integrated Installment Credit",
		issuedCharge: "Issued Charge",
		softwareLicenseGiftback: "Giftback Software License",
		loanBracelet: "Loan Bracelet",
		lendingCards: "Leading Cards",
		externalResourceContribution: "External resource allocation",
		integratedOnlineDeliveryIncome: "Integrated online delivery income",
		generalZigMachineCreditIn2Installments: "TPV Credit in 2 installments",
		generalZigMachineCreditIn3Installments: "TPV Credit in 3 installments",
		generalZigMachineCreditIn4Installments: "TPV Credit in 4 installments",
		generalZigMachineCreditIn5Installments: "TPV Credit in 5 installments",
		generalZigMachineCreditIn6Installments: "TPV Credit in 6 installments",
		generalZigMachineCreditIn7Installments: "TPV Credit in 7 installments",
		generalZigMachineCreditIn8Installments: "TPV Credit in 8 installments",
		generalZigMachineCreditIn9Installments: "TPV Credit in 9 installments",
		generalZigMachineCreditIn10Installments: "TPV Credit in 10 installments",
		generalZigMachineCreditIn11Installments: "TPV Credit in 11 installments",
		generalZigMachineCreditIn12Installments: "TPV Credit in 12 installments",
		generalZigMachineCreditIn2InstallmentsFEE: "Fee for Credit in 2 installments",
		generalZigMachineCreditIn3InstallmentsFEE: "Fee for Credit in 3 installments",
		generalZigMachineCreditIn4InstallmentsFEE: "Fee for Credit in 4 installments",
		generalZigMachineCreditIn5InstallmentsFEE: "Fee for Credit in 5 installments",
		generalZigMachineCreditIn6InstallmentsFEE: "Fee for Credit in 6 installments",
		generalZigMachineCreditIn7InstallmentsFEE: "Fee for Credit in 7 installments",
		generalZigMachineCreditIn8InstallmentsFEE: "Fee for Credit in 8 installments",
		generalZigMachineCreditIn9InstallmentsFEE: "Fee for Credit in 9 installments",
		generalZigMachineCreditIn10InstallmentsFEE: "Fee for Credit in 10 installments",
		generalZigMachineCreditIn11InstallmentsFEE: "Fee for Credit in 11 installments",
		generalZigMachineCreditIn12InstallmentsFEE: "Fee for Credit in 12 installments",
		totalTpvBarIncome: "Bar income rate",
		totalTpvEntranceIncome: "Ticket office income rate",
		totalTpvEntranceIncomeConsumption: "Ticket consumption income rate",
		totalTpvBarMinimumConsumption: "Minimum bar income rate",
		totalTpvBonus: "Bonus income rate",
		bankRefund: "Bank Refund",
		acquirerRefund: "Acquirer Refund",
		advanceRenegotiation: "Advance Renegotiation",
		zigTicketsRetention: "Zig Tickets Retention",
		onlineDeliveryAcquisitionFee: "Online Delivery Acquisition Fee",
		pixPreventiveBlock: "Pix - Preventive Block",
		pixAuthorizedUnblock: "Pix - Authorized Unblock",
		pixRefundByChargeback: "Pix - Refund by Chargeback",
	},
	table: {
		date: "Competency date",
		payDate: "Settlement date",
		category: "Category",
		obs: "Observation",
		noObs: "No observation",
		totalToReceive: "Receivable",
		totalToPay: "Payable",
		balance: "Balance",
		actions: "Actions",
		total: "Total",
	},
};
