import {
	BackofficePurposeTypes,
	BasicStorageUnit,
	PaymentMethod,
	TypeInterval,
	WeekDay,
} from "#api/enterprise-generated";
import i18n from "#i18n/index";

const t = i18n.t;

export type IPaymentMethod =
	| "anticipated"
	| "ZigDebit"
	| "ZigCredit"
	| "Anticipated"
	| "bleedings"
	| "BankTransfer"
	| "Check"
	| "consumptionFromBar"
	| "CreditCard"
	| "credit"
	| "Credit"
	| "DebitCard"
	| "debit"
	| "Debit"
	| "ZigPosCredit"
	| "ZigPosDebit"
	| "Voucher"
	| "voucher"
	| "Dashboard"
	| "dashboard"
	| "postEvent"
	| "PostEvent"
	| "zigDebit"
	| "zigCredit"
	| "App"
	| "app"
	| "Cash"
	| "cash"
	| "Money"
	| "Boleto"
	| "AutomaticDebit"
	| "Promissory"
	| "rappi"
	| "Rappi"
	| "deliveryOnline"
	| "ame"
	| "Ame"
	| "uber"
	| "ifood"
	| "Ifood"
	| "pix"
	| "Pix"
	| "bonus"
	| "IntegratedFoodVoucher"
	| "ZigPosCreditInInstallments"
	| null
	| undefined
	| "MBWay"
	| "DeliveryOnline";

export const parsePaymentMethod = (rawMethod: IPaymentMethod): string => {
	switch (rawMethod) {
		case "anticipated":
		case "Anticipated":
			return t("org:helpers.humanize.paymentMethod.anticipated");

		case "bleedings":
			return t("org:helpers.humanize.paymentMethod.bleedings");

		case "deliveryOnline":
			return t("org:helpers.humanize.paymentMethod.deliveryOnline");
		case "ame":
		case "Ame":
			return t("org:helpers.humanize.paymentMethod.ame");
		case "uber":
			return t("org:helpers.humanize.paymentMethod.uber");
		case "ifood":
		case "Ifood":
			return t("org:helpers.humanize.paymentMethod.ifood");

		case "App":
		case "app":
			return t("org:helpers.humanize.paymentMethod.app");

		case "cash":
		case "Cash":
		case "Money":
			return t("org:helpers.humanize.paymentMethod.cash");

		case "AutomaticDebit":
			return t("org:helpers.humanize.paymentMethod.automaticDebit");

		case "Boleto":
			return t("org:helpers.humanize.paymentMethod.boleto");

		case "BankTransfer":
			return t("org:helpers.humanize.paymentMethod.bankTransfer");

		case "Promissory":
			return t("org:helpers.humanize.paymentMethod.promissory");

		case "Check":
			return t("org:helpers.humanize.paymentMethod.check");

		case "CreditCard":
		case "credit":
		case "Credit":
			return t("org:helpers.humanize.paymentMethod.creditCard");

		case "ZigPosCredit":
			return t("org:helpers.humanize.paymentMethod.zigPosCredit");

		case "zigCredit":
		case "ZigCredit":
			return t("org:helpers.humanize.paymentMethod.zigCredit");

		case "DebitCard":
		case "Debit":
		case "debit":
			return t("org:helpers.humanize.paymentMethod.debitCard");

		case "ZigPosDebit":
			return t("org:helpers.humanize.paymentMethod.zigPosDebit");

		case "ZigPosCreditInInstallments":
			return t("org:helpers.humanize.paymentMethod.zigPosCreditInInstallments");

		case "zigDebit":
		case "ZigDebit":
			return t("org:helpers.humanize.paymentMethod.zigDebit");

		case "Voucher":
		case "voucher":
			return t("org:helpers.humanize.paymentMethod.voucher");

		case "Dashboard":
		case "dashboard":
			return t("org:helpers.humanize.paymentMethod.dashboard");

		case "postEvent":
		case "PostEvent":
			return t("org:helpers.humanize.paymentMethod.postEvent");

		case "rappi":
		case "Rappi":
			return t("org:helpers.humanize.paymentMethod.rappi");

		case "consumptionFromBar":
			return t("org:helpers.humanize.paymentMethod.consumptionFromBar");

		case "pix":
		case "Pix":
			return t("org:helpers.humanize.paymentMethod.pix");

		case "bonus":
			return t("org:helpers.humanize.paymentMethod.bonus");

		case "IntegratedFoodVoucher":
			return t("org:helpers.humanize.paymentMethod.integratedFoodVoucher");
		case "MBWay":
			return "MBWay";

		default:
			return t("org:helpers.humanize.paymentMethod.other");
	}
};

export const PaymentMethodLabels: Record<PaymentMethod, string> = {
	Bonus: t("components:helpers.parsePaymentMethodV2.bonus"),
	Cash: t("components:helpers.parsePaymentMethodV2.cash"),
	CreditCard: t("components:helpers.parsePaymentMethodV2.creditCard"),
	DebitCard: t("components:helpers.parsePaymentMethodV2.debitCard"),
	PostEvent: t("components:helpers.parsePaymentMethodV2.postEvent"),
	Voucher: t("components:helpers.parsePaymentMethodV2.voucher"),
	ZigPosCredit: t("components:helpers.parsePaymentMethodV2.zigPosCredit"),
	ZigPosDebit: t("components:helpers.parsePaymentMethodV2.zigPosDebit"),
	Pix: t("components:helpers.parsePaymentMethodV2.pix"),
	IntegratedFoodVoucher: t("components:helpers.parsePaymentMethodV2.foodVoucher"),
	ZigPosCreditInInstallments: t(
		"components:helpers.parsePaymentMethodV2.zigPosCreditInInstallments",
	),
	Anticipated: t("components:helpers.parsePaymentMethodV2.anticipated"),
	Ame: t("components:helpers.parsePaymentMethodV2.ame"),
	MBWay: "MBWay",
	DeliveryOnline: t("components:helpers.parsePaymentMethodV2.deliveryOnline"),
};

export const parsePaymentMethodV2 = (method: PaymentMethod) => {
	return PaymentMethodLabels[method];
};

export const parseBasicUnit = (unit: BasicStorageUnit) => {
	const parser: Record<BasicStorageUnit, string> = {
		milliliter: "mL",
		liter: "L",
		kilogram: "Kg",
		gram: "g",
		unit: "un",
	};

	return parser[unit];
};

export const parseTypeIntervalDate = (interval: TypeInterval) => {
	const parser: Record<TypeInterval, string> = {
		Day: t("globalKeys:typeDate.Day"),
		Week: t("globalKeys:typeDate.Week"),
		Month: t("globalKeys:typeDate.Month"),
		Year: t("globalKeys:typeDate.Year"),
	};

	return parser[interval];
};

export type BackofficePurposeTypesParse = Exclude<
	BackofficePurposeTypes,
	| "PaymentAccount"
	| "ReceivingAccount"
	| "eWallet"
	| "Tip"
	| "SecuredAccount"
	| "LoanAccount"
	| "CardIssuer"
	| "Advance"
>;

type IParsePurpose = Record<BackofficePurposeTypesParse, BackofficePurposeTypesParse>;

export const parsePurpose: IParsePurpose = {
	CheckingAccount: t("backoffice:bankAccounts.form.purposeList.checkingAccount"),
	SavingsAccount: t("backoffice:bankAccounts.form.purposeList.savingsAccount"),
	InvestmentAccount: t("backoffice:bankAccounts.form.purposeList.applicationAccount"),
	InternalCash: t("backoffice:bankAccounts.form.purposeList.inHouseFlow"),
	CreditCard: t("backoffice:bankAccounts.form.purposeList.creditCard"),
};

export const parseWeekDay = (weekDay: WeekDay) => {
	const parser: Record<WeekDay, string> = {
		mon: t("globalKeys:daysOfTheWeekComplete.monday"),
		tue: t("globalKeys:daysOfTheWeekComplete.tuesday"),
		wed: t("globalKeys:daysOfTheWeekComplete.wednesday"),
		thu: t("globalKeys:daysOfTheWeekComplete.thursday"),
		fri: t("globalKeys:daysOfTheWeekComplete.friday"),
		sat: t("globalKeys:daysOfTheWeekComplete.saturday"),
		sun: t("globalKeys:daysOfTheWeekComplete.sunday"),
	};

	return parser[weekDay];
};

export const parseAccountPurpose = (purpose: BackofficePurposeTypesParse) => {
	return parsePurpose[purpose];
};
