export const tableProductsSold = {
	label: "Saída de produtos em mesa",
	mainTitle: "Performance das mesas",
	mainSubtitle:
		"Acompanhe o desempenho das mesas e potencialize a gestão do seu restaurante.",
	tabName: "Performance das mesas",
	title: "Saída de produtos em mesa",
	filters: {
		table: "Mesa",
		employeeName: "Garçom",
		payment: "Pagamento",
		product: "Produto",
		tablePlaceholder: "Selecione uma mesa",
		employeeNamePlaceholder: "Todos",
		status: "Status",
		dateOrPeriod: "Data ou período",
		paymentPlaceholder: "Todos",
		productPlaceholder: "Todos",
	},
	table: {
		title: "Consumo de mesas",
		columns: {
			tableName: "MESA",
			employeeName: "GARÇOM",
			numberOfSeats: "PESSOAS",
			openedAt: "ABERTURA",
			permanence: "PERMANÊNCIA",
			tip: "SERVIÇO",
			productsValue: "PRODUTOS",
			closedAt: "FECHAMENTO",
			discount: "DESCONTOS",
			refundValue: "ESTORNOS",
			totalValue: "TOTAL",
			products: {
				imageUrl: "FOTO",
				sku: "SKU",
				name: "NOME DO PRODUTO",
				quantity: "QUANTIDADE",
				unitPrice: "VALOR UNITÁRIO",
				subTotal: "SUBTOTAL",
				discount: "DESCONTOS",
				total: "VALOR TOTAL",
			},
		},
	},
};
