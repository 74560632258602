import enterprise from "#resources/api/enterprise-client";
import { fetchModel } from "#resources/helpers/fetch-model";

import {
	AdjustResponse,
	Attachment,
	BankAccountRequest,
	BankCodeResponse,
	BankslipInfo,
	ConsolidatedExtract,
	CreateWithdrawDto,
	DayResume,
	DebitNote,
	ExtractDayResumeSection,
	ExtractFlowRow,
	ExtractResume,
	GetExtractOfValuesOwedToTheVendors,
	Pagination,
	PlaceBankAccountPaginationResponse,
	PlaceBankAccountResponse,
	RechargeExpireDayResume,
	RechargeExpireResume,
	RentNote,
	VendorFinancialAdjustDto,
	VendorFinancialAdjustResponse,
	VendorWithdrawalRequestResponse,
	VendorWithdrawRequestHistoryResponse,
	VendorWithdrawRequestMethod,
	Withdraw,
	WithdrawResponse,
} from "#resources/api/enterprise-generated";
import {
	showErrorNotification,
	showSuccessNotification,
} from "#resources/helpers/notifications";
import { jobExecutor } from "#resources/helpers/job-executor";
import i18n from "#i18n/index";
import { EnterpriseApiSignature } from "#resources/helpers/api";

export interface GetWithdrawsParams {
	placeId: string;
	since: Date;
	until: Date;
}

const t = i18n.t;

export class ExtractStore {
	// RESUME
	public extractResume = new fetchModel<{ placeId: string }, ExtractResume>({
		fnPromise: args => enterprise.getExtractResume(args),
		onError: err => showErrorNotification(err.message),
	});

	public getExtractDayResumeInSections = new fetchModel<
		{
			placeId: string;
			date: Date;
		},
		ExtractDayResumeSection[]
	>({
		fnPromise: args => enterprise.getExtractDayResumeInSections(args),
		onError: err => showErrorNotification(err.message),
	});

	// FLOW
	public getExtractFlow = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
		},
		ExtractFlowRow[]
	>({
		fnPromise: args => enterprise.getExtractFlow(args),
		onError: err => showErrorNotification(err.message),
	});

	// CONSOLIDATED
	public getConsolidatedExtractInSections = new fetchModel<
		GetWithdrawsParams,
		ConsolidatedExtract
	>({
		fnPromise: args => enterprise.getConsolidatedExtractInSections(args),
		onError: err => showErrorNotification(err.message),
	});

	// FINANCIAL
	public extract = new fetchModel<GetWithdrawsParams, DayResume[]>({
		fnPromise: args => enterprise.extract(args),
		onError: err => showErrorNotification(err.message),
	});

	// WITHDRAWS
	public getWithdraws = new fetchModel<GetWithdrawsParams, Withdraw[]>({
		fnPromise: args => enterprise.getWithdraws(args),
		onError: err => showErrorNotification(err.message),
	});

	// DEBIT NOTES
	public getDebitNotes = new fetchModel<GetWithdrawsParams, DebitNote[]>({
		fnPromise: args => enterprise.getDebitNotes(args),
		onError: err => showErrorNotification(err.message),
	});

	public createWithdraw = new fetchModel<
		{
			placeId: string;
			newWithdraw: CreateWithdrawDto;
		},
		WithdrawResponse
	>({
		fnPromise: args => enterprise.createWithdraw(args),
		onSuccess: (_, { placeId }) => this.extractResume.fetch({ placeId }),
		onError: err => showErrorNotification(err.message),
	});

	public addAttachmentsToWithdraw = new fetchModel<
		{
			withdrawId: string;
			attachments: Attachment[];
		},
		void
	>({
		fnPromise: args => enterprise.addAttachmentsToWithdraw(args),
		onError: err => showErrorNotification(err.message),
	});

	public getRequestProRataPercentage = new fetchModel<
		{
			placeId: string;
			anticipationValue: number;
		},
		number
	>({
		fnPromise: args => enterprise.getRequestProRataPercentage(args),
		onError: err => showErrorNotification(err.message),
	});

	public getMaximumPossibleAnticipationValues = new fetchModel<
		EnterpriseApiSignature<"getMaximumPossibleAnticipationValues">["args"],
		EnterpriseApiSignature<"getMaximumPossibleAnticipationValues">["result"]
	>({
		fnPromise: args => enterprise.getMaximumPossibleAnticipationValues(args),
		onError: err => showErrorNotification(err.message),
	});

	public transferBalance = new fetchModel<
		{
			fromPlaceId: string;
			toPlaceId: string;
			transferValue: number;
		},
		void
	>({
		fnPromise: args => enterprise.transferBalance(args),
		onError: err => showErrorNotification(err.message),
	});

	public isWithdrawRequestPendingPlaceConfirmation = new fetchModel<
		EnterpriseApiSignature<"isWithdrawRequestPendingPlaceConfirmation">["args"],
		EnterpriseApiSignature<"isWithdrawRequestPendingPlaceConfirmation">["result"]
	>({
		fnPromise: args => enterprise.isWithdrawRequestPendingPlaceConfirmation(args),
		onError: err => showErrorNotification(err.message),
	});

	// DEVOLUTION
	public getRechargeExpireExtract = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
		},
		RechargeExpireDayResume[]
	>({
		fnPromise: args => enterprise.getRechargeExpireExtract(args),
		onError: err => showErrorNotification(err.message),
	});

	public getRechargeExpireResume = new fetchModel<
		{ placeId: string; since: Date; until: Date },
		RechargeExpireResume
	>({
		fnPromise: args => enterprise.getRechargeExpireResume(args),
		onError: err => showErrorNotification(err.message),
	});

	// ADJUSTMENTS
	public getExtractAdjusts = new fetchModel<GetWithdrawsParams, AdjustResponse[]>({
		fnPromise: args => enterprise.getExtractAdjusts(args),
		onError: err => showErrorNotification(err.message),
	});

	// BANK ACCOUNTS
	public createBankAccount = new fetchModel<
		{
			placeId: string;
			newBankAccount: BankAccountRequest;
			shouldSaveBankAccount: boolean;
		},
		PlaceBankAccountResponse
	>({
		fnPromise: args => enterprise.createBankAccount(args),
		onSuccess: (_, { placeId }) => {
			this.getBankAccounts.fetch({
				placeId,
				pagination: {
					currentPage: 1,
					perPage: 100,
				},
			}),
				showSuccessNotification(t("store:extractStore.createBankAccount"));
		},
		onError: err => showErrorNotification(err.message),
	});

	public getBankAccount = new fetchModel<
		{ bankAccountId: string },
		PlaceBankAccountResponse
	>({
		fnPromise: args => enterprise.getBankAccount(args),
		onError: err => showErrorNotification(err.message),
	});

	public getBankAccounts = new fetchModel<
		{ placeId: string; pagination: Pagination; bankName?: string | null },
		PlaceBankAccountPaginationResponse
	>({
		fnPromise: args => enterprise.getBankAccounts(args),
		onError: err => showErrorNotification(err.message),
	});

	public deleteBankAccount = new fetchModel<{ bankAccountId: string }, void>({
		fnPromise: args => enterprise.deleteBankAccount(args),
		onSuccess: () => showSuccessNotification(t("store:extractStore.deleteBankAccount")),
		onError: err => showErrorNotification(err.message),
	});

	public updateBankAccount = new fetchModel<
		{ bankAccountId: string; bankAccount: BankAccountRequest },
		PlaceBankAccountResponse
	>({
		fnPromise: args => enterprise.updateBankAccount(args),
		onSuccess: () => showSuccessNotification(t("store:extractStore.updateBankAccount")),
		onError: err => showErrorNotification(err.message),
	});

	// MULTIVENDOR

	public getOpenVendorWithdrawRequests = new fetchModel<
		{
			placeId: string;
		},
		VendorWithdrawalRequestResponse[]
	>({
		fnPromise: args => enterprise.getOpenVendorWithdrawRequests(args),
		onError: err => showErrorNotification(err.message),
	});

	public getVendorExtractAdjusts = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
		},
		VendorFinancialAdjustResponse[]
	>({
		fnPromise: args => enterprise.getVendorExtractAdjustments(args),
		onError: err => showErrorNotification(err.message),
	});

	public getVendorWithdrawRequestsHistory = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
		},
		VendorWithdrawRequestHistoryResponse[]
	>({
		fnPromise: args => enterprise.getVendorWithdrawRequestsHistory(args),
		onError: err => showErrorNotification(err.message),
	});

	public getExtractOfValuesOwedToTheVendor = new fetchModel<
		{
			placeId: string;
		},
		GetExtractOfValuesOwedToTheVendors
	>({
		fnPromise: args => enterprise.getExtractOfValuesOwedToTheVendors(args),
		onError: err => showErrorNotification(err.message),
	});

	public createAdjustmentInVendorExtract = new fetchModel<
		{
			adjust: VendorFinancialAdjustDto;
		},
		void
	>({
		fnPromise: args => enterprise.createAdjustmentInVendorExtract(args),
		onSuccess: () =>
			showSuccessNotification(t("store:extractStore.createAdjustmentInVendorExtract")),
		onError: err => showErrorNotification(err.message),
	});

	public payVendorWithdrawRequests = new fetchModel<
		{
			placeId: string;
			withdrawIds: string[];
			methodType: VendorWithdrawRequestMethod;
			attachments?: Attachment[] | null | undefined;
			obs?: string | null;
		},
		void
	>({
		fnPromise: args => enterprise.payVendorWithdrawRequests(args),
		onSuccess: () =>
			showSuccessNotification(t("store:extractStore.payVendorWithdrawRequests")),
		onError: err => showErrorNotification(err.message),
	});

	public revertManyVendorsWithDrawRequests = new fetchModel<
		{
			placeId: string;
			withdrawIds: string[];
			reason: string;
		},
		void
	>({
		fnPromise: args =>
			new Promise<void>((resolve, reject) => {
				jobExecutor(
					args.withdrawIds,
					withdrawId =>
						enterprise.revertVendorWithdrawRequest({
							placeId: args.placeId,
							reason: args.reason,
							withdrawId,
						}),
					() => {},
					err => reject(err),
				).then(resolve);
			}),
		onSuccess: () =>
			showSuccessNotification(t("store:extractStore.revertManyVendorsWithDrawRequests")),
		onError: err => showErrorNotification(err.message),
	});

	public revertVendorWithdrawRequest = new fetchModel<
		{
			placeId: string;
			withdrawId: string;
			reason: string;
		},
		void
	>({
		fnPromise: args => enterprise.revertVendorWithdrawRequest(args),
		onSuccess: () =>
			showSuccessNotification(t("store:extractStore.revertManyVendorsWithDrawRequests")),
		onError: err => showErrorNotification(err.message),
	});

	public getBankCodes = new fetchModel<{}, BankCodeResponse[], BankCodeResponse[]>({
		fnPromise: args => enterprise.getBankCodes(args),
		onError: err => showErrorNotification(err.message),
		transform: banks => {
			return banks.sort((a, b) => a.name.localeCompare(b.name));
		},
	});

	public resendWithdrawConfirmation = new fetchModel<
		EnterpriseApiSignature<"resendWithdrawRequestPlaceConfirmationCode">["args"],
		EnterpriseApiSignature<"resendWithdrawRequestPlaceConfirmationCode">["result"]
	>({
		fnPromise: args => enterprise.resendWithdrawRequestPlaceConfirmationCode(args),
		onSuccess: () =>
			showSuccessNotification(
				t("place:extract.withdrawConfirmation.resendConfirmationSuccessfully"),
			),
		onError: err => showErrorNotification(err.message),
	});

	public validateWithdrawConfirmationCode = new fetchModel<
		EnterpriseApiSignature<"validateWithdrawPlaceConfirmationCode">["args"],
		EnterpriseApiSignature<"validateWithdrawPlaceConfirmationCode">["result"]
	>({
		fnPromise: args => enterprise.validateWithdrawPlaceConfirmationCode(args),
	});
	public getPendingBankslip = new fetchModel<{ placeId: string }, BankslipInfo>({
		fnPromise: args => enterprise.isThereAPendingBankslip(args),
		onError: err => showErrorNotification(err.message),
	});

	public cancelWithdrawPlaceConfirmation = new fetchModel<
		EnterpriseApiSignature<"cancelWithdrawRequestPlaceConfirmation">["args"],
		EnterpriseApiSignature<"cancelWithdrawRequestPlaceConfirmation">["result"]
	>({
		fnPromise: args => enterprise.cancelWithdrawRequestPlaceConfirmation(args),
		onError: () => {
			showErrorNotification(t("place:extract.withdrawRequestDetails.errorNotification"));
		},
		onSuccess: () => {
			showSuccessNotification(
				t("place:extract.withdrawRequestDetails.successNotification"),
			);
		},
	});

	public orderWithdrawCancellation = new fetchModel<{ withdrawId: string }, void>({
		fnPromise: args => enterprise.orderWithdrawCancellation(args),
		onSuccess: () =>
			showSuccessNotification(t("store:extractStore.orderWithdrawCancellation")),
		onError: err => showErrorNotification(err.message),
	});

	public getLocationNotes = new fetchModel<
		{ placeId: string; since: Date; until: Date },
		RentNote[]
	>({
		fnPromise: args => enterprise.getRentNotes(args),
		onError: err => showErrorNotification(err.message),
	});
}
