import { createQueryFactory } from "../query-factory";

export const { useApiHook: useGetAllPlaceSales } = createQueryFactory(
	"getAllPlaceSales",
	{
		retry: false,
	},
);

export const { useApiHook: useGetAllPlaceSalesInEvent } = createQueryFactory(
	"getAllPlaceSalesInEvent",
	{
		retry: false,
	},
);

export const { useApiHook: useGetAllPlaceSalesSummary } = createQueryFactory(
	"getAllPlaceSalesSummary",
	{
		retry: false,
	},
);

export const { useApiHook: useGetAllPlaceSalesSummaryInEvent } = createQueryFactory(
	"getAllPlaceSalesSummaryInEvent",
	{
		retry: false,
	},
);
