import { createMutationFactory } from "../mutation-factory";

export const { useApiHook: useGetAllPlaceSalesXls } = createMutationFactory(
	"getAllPlaceSalesXls",
	{
		retry: false,
	},
);

export const { useApiHook: useProductsSoldXlsx } = createMutationFactory(
	"productsSoldXlsx",
);

export const { useApiHook: useProductsSoldByBarXlsx } = createMutationFactory(
	"productsSoldByBarXlsx",
);

export const { useApiHook: useGetAllPlaceSalesInEventXls } = createMutationFactory(
	"getAllPlaceSalesInEventXls",
);
